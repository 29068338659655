<template>
  <div class="home">
    <!-- 这是网页的头部版心盒子 -->
    <div class="header">
      <div>
        <img src="../image/logo.jpg" alt="">
      </div>
      <div class="header_search">
        搜索：<input type="text" placeholder="请输入搜索内容">
      </div>
    </div>
    <!-- 导航栏平铺部分：会显示黑色啦 -->
    <div id="nav">
      <!-- 导航栏版心部分 -->
      <div class="nav_con">
        <ul>
          <li><a href="#">公司简介</a></li>
          <li><a href="#">公司详情</a></li>
          <li><a href="#">公司新闻</a></li>
          <li><a href="#">公司经营</a></li>
        </ul>
      </div>
    </div>
    <!-- banner的平铺部分 -->
    <div id="banner">

      <div class="banner_con header_img">
        <!-- <img src="./image/banner1_02.jpg"> -->
      </div>
    </div>
    <!-- 新闻列表的版心部分 -->
    <div class="news_con">
      <!-- 最左侧的盒子 -->
      <div class="news_l">
        <h3 class="news_title">公司简介</h3>
        <ul class="news_list">
          <p>简介：济南医宠点评信息科技有限公司成立于2023年09月26日，注册地位于山东省济南市槐荫区烟台路西元大厦1-501，法定代表人为薛汇文。</p>
        </ul>

      </div>
      <!-- 中间的盒子-->
      <div class="news_c">
        <h3 class="news_title">公司经营</h3>
        <p>
          一般项目：信息技术咨询服务；组织文化艺术交流活动；文艺创作；咨询策划服务；电子产品销售；畜牧渔业饲料销售；玩具销售；计算机软硬件及辅助设备零售；计算机软硬件及辅助设备批发；软件销售；软件开发；食品销售（仅销售预包装食品）；货物进出口；企业管理；社会经济咨询服务；市场调查（不含涉外调查）；广告制作；广告发布；广告设计、代理；医院管理；宠物服务（不含动物诊疗）；企业管理咨询；旅游开发项目策划咨询；软件外包服务；信息系统运行维护服务；数据处理服务；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广
        </p>
      </div>
      <!-- 右边的盒子 -->
      <div class="news_r">
        <h3>基本信息</h3>
        <p class="txt3">公司邮箱:cydp001@163.com</p>
        <p class="txt3">联系电话:15668357202</p>
        <p class="txt3">公司法人:薛汇文</p>

      </div>
    </div>
    <!-- 市场项目那一部分 -->
    <div class="case_con">
      <h3>公司新闻</h3>
      <div class="case_box">
        <div>
          3月1日高薪聘请了一名高级程序开发人员
        </div>
        <div>
          3月5号开展全体员工大扫除活动
        </div>
        <div>
          3月8号开展户外野餐,每个人都很开心
        </div>
        <div>
          3月12号公司内部规定调整
        </div>
      </div>
    </div>

    <!-- 产品中心那一部分的平铺-->
    <div class="case_con">
      <h3>公司产品</h3>
      <div class="case_box">
        <div>
          <div>
            应用产品
          </div>

          <div>应用名称：医宠点评</div>
          <div>
            应用图标：<img style="width: 100px;height: 100px;"
              src="https://yichong-1320557350.cos.ap-shanghai.myqcloud.com/24a471bad595a12a9cce1c5931f58ea.png" alt="">
          </div>
          <div>应用简介：专注宠物医疗和动物保护</div>
          <div>
            应用描述：<p>
              养宠家庭最实用、最有趣的APP：
              宠物医疗： 为您推荐服务优质、价格透明的医院，提供最专业最负责的医生供您选择；
              动物保护：直播流浪小动物，在您的城市举办志愿者活动，期待遇到您的流浪小天使；
              宠物档案：为您的爱宠创建健康档案，方便爱宠就医，同时通过大数据进行健康状态分析，为您提示小动物的健康风险，早发现早干预，让小家伙们更健康更开心；
              寻宠：结合了地图定位系统，让寻宠信息传播更高效；
              本地生活：为宠物主推荐身边优质平价的宠物生活服务；
              宠物社群：分享养宠生活、学习养宠知识、交流养宠经验、变身养宠达人；
            </p>
          </div>
        </div>

      </div>
    </div>
    <!-- 隐私政策 -->
    <div>
      <div style="text-align: center;">
        隐私政策
      </div>
      <div style="padding: 0px 120px;">
        <p style="font-size: 20px;font-weight: 600;margin: 10px 0px;">医宠点评科技用户服务协议</p> 

本协议《医宠点评科技用户服务协议》（以下简称：本协议）是您与医宠点评科技（以下简称本平台）之间就账户的注册、使用及使用平台上各项服务所订立的协议。为使用本平台服务，您应当仔细阅读并遵守本协议中的全部条款，尤其是涉及免除限制责任、限制权利、提示风险的部分，该类条款会采用黑体、加粗或下划线的形式予以突出，以引起您的注意。

在注册使用本平台前，您应当已经阅读、充分了解本协议并同意本协议中的全部条款，否则您不得注册本平台的账号或使用本平台的任何服务，如您不同意本协议中的任意条款，请勿注册或使用本平台并应当立即停止使用本平台。如您对本协议内容有疑义，欢迎您与本平台
联系，要求进一步解释及说明。

如您勾选“我同意《医宠点评科技用户服务协议》”并在本平台注册账号，即视为您已经完整阅读并充分理解了本协议并自愿遵守本协议中的所有约定。

您应当保证您为年满十八周岁，有能力独立理解并对本协议做出认同的意思表示的完全民事行为能力人，如您未满十八周岁，则应当在家长及其他监护人的陪同下阅读本协议并在使用本平台时获得监护人的许可。

以法人或非法人组织身份注册、使用本平台时，应当提前获得相关组织机构的充分授权并按照本协议约定提供相应证明材料。注册资料通过本平台审核后，本平台即开放账号的使用权限，如对账号的注册、使用及管理权限存在争议，本平台不提供除本协议约定外的服务。

本协议适用于在本平台注册账号、使用本平台服务的全部主体、通过本平台发布的全部信息及提供的全部服务。

本协议的订立依据中华人民共和国现行有效的法律法规及行业规范，前述规则如产生变化，本平台有权依据规则变化的本协议内容进行增加、变更、删减，依法做变更的部分自动构成本协议的部分。此外，本平台有权出于经营调整等情况对本协议内容进行增加、变更、删减。本平台将以平台公告的形式对相关变化进行公告，变更部分在公告期满7日或相关法律、法规及行业规范生效之日自动生效。如您在条款变化后继续使用本平台，则视为您同意本平台有权限进行上述调整，自愿遵守变更后的全部条款。如您对变更条款不认可的，请立即停止使用本平台。

本协议适用于本平台的各个版本及客户端。

本协议项下条款存在冲突的，以最新发布的内容为准，本协议项下部分条款存在效力瑕疵的，不影响本协议其他部分的效力。
定义
1.本平台：即医宠点评科技     （公司），本平台在提供服务时会标明“本平台”并做出明确申明，非以本平台名义发布的信息、提供的服务本平台均不承担任何责任。
2.用户：即通过本平台注册程序，注册本平台账号并在本平台上开展活动，使用本平台各项服务的主体。
3.平台服务：即本平台为用户及商家提供的点评、支付、助养领养、直播等服务功能及以本平台名义直接发起的各项活动
4.第三方服务关联授权：您需要通过本平台跳转方式自愿使用独立第三方提供的服务时需要在使用相应服务前就必要的用户授权协议进行确认以作为您和本平台直接就使用第三方服务涉及相关事项的依据。

<p style="font-size: 20px;font-weight: 600;margin: 10px 0px;">一、账号的注册与使用</p> 
（一）注册
1．每位用户有权以确定个体身份在本平台注册一个账号，不得使用他人信息注册账号。
2．您在注册账号时应当按照本平台要求提交必要的注册资料，并确保提交的资料真实有效。
3．您承诺向本平台提交的资料符合相关法律法规的规定，不得提交任何不实、不良或不法信息，提交的资料发生变化的应当及时更新。
4．如您提交的资料存在不实、不准确、不合法或失效的情况，因此产生的任何法律责任或不良后果由您承担。
5．您确认，在本平台账号注册过程中，因数据管理、系统支持及安全性等问题，部分资料一经提交无法再次更改，故您应当审慎对待需要提交的资料，确保提交内容的真实准确。
6．您知悉并承诺，您具有以您申请的主体独立申请本平台账号的能力及完整权限，如您不具有与注册使用本平台相适应的民事行为能力及权限，因此产生的一切后果由您及您的法定代理人承担。
7．因国家法律法规、部门规章及相关主管部门的监管需求，在您使用特定服务时您可能需要在注册及使用该类服务的特定环节中提交真实身份信息，相关信息须通过必要的验证流程，如您提交的身份信息不真实、不准确、不完整或验证失败，则可能导致您无法使用该类服务，因提交信息错误损害的自身、本平台或第三人权益的一切不利后果由您承担。
8．您向本平台提交的信息，仅用于注册及协助本平台为您提供相应服务，对于您上传的信息本平台将予以保护，不会向任何非关联第三方披露，但存在以下情形的除外：
（1）按照相关法律法规应当披露的
（2）司法机关要求披露的
（3）为提供服务而必须使用的
（4）本平台经营主体发生变更的（如：合并、分立、收购等）
9．您提交的注册信息将按照本平台《隐私保护政策》的规定进行收集、管理及使用。
（二）使用
1．您可以通过注册时登记的账号密码登录并使用您的账号，为了您使用的便利，本平台会为您提供其他更便利的登录方式，但本平台可根据相关法律法规及主管部门的要求、风险控制、用户体验等情况临时或永久调整登录方式，或通过具体条款对登录方式进行优化调整。
2．您使用您的账号实施的所有行为（包括但不限于签署的任何协议，上传、发布、提交、获得的任何信息、进行的付行为及以各种形式使用本平台）均为您真实意思的表示，由您对签署行为产生的以其结果负责。
3．您承诺在使用本平台时自觉主动遵守相关法律法规的规定，并承诺如下：
（1）不得利用本平台实施害国家安全、破坏政治稳定、泄露国家秘密，不侵犯国家、社会、集体利益和第三方的合法权益。
（2）不得实施反对宪法所确定的基本原则的，危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一，损害国家荣誉和利益，歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉，宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动，煽动民族仇恨、民族歧视，破坏民族团结，破坏国家宗教政策，宣扬邪教和封建迷信，散布谣言，扰乱经济秩序和社会秩序的，散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪，侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的行为及法律、行政法规禁止的其他内容。
（3）不应从事下列危害计算机信息网络安全的活动：对网络服务及相关软硬件设施进行破解、破坏、删除、修改或者增加的；对计算机信息网络中存储或者传输的数据和应用程序进行删除、修改或者增加的；使用软件或硬件等方式窃取他人口令、非法入侵他人计算机系统；故意制作、传播计算机病毒等破坏性程序的；其他危害计算机信息网络安全的活动；
（4）不应擅自复制或未获得授权的图片、视频、音频、文本等文件，不得在网络中擅自破解、传播、下载或复制第三人享有著作权的软件或进行其他任何侵害他人知识产权的活动；
（5）不的发布、宣扬不正当使用本平台服务的方法（如“刷单、刷好差评”；以前述行为威胁用户等）；
（6）不得对本平台发布不实评价或引导舆论对本平台产生负面评价；
（7）不得通过技术手段为非友好使用本平台服务的目的注册账号，或使用账号实施虚假、提交虚假评价、发布虚假消息或使用前述账号参加本平台或其所授权的第三方发布的任何活动；
（8）不得自行或授权、协助任何第三方使用技术手段或本平台未许可的程序非法获取（如批量抓取、导出）本平台所展示的任何内容。 
4．使用本平台商业服务及直播服务时，您应当享有相应的资质在注册及开通相应服务时向本平台提交全部必要资料，自觉遵守相应法律法规。
5．如需要在本平台进行支付，需要您对本平台的合作第三方结算机构mallbook进行必要的授权，如拒绝授权将限制您在本平台支付的权限。
（三）责任承担
1．注册的账号仅限账号登记主体使用，账号登记主体（无论是否实际使用相应账号），对该账号在本平台实施的所有行为承担责任，您不得将个人注册的账号转借给他人使用，否则因此产生的不利后果（包括但不限于本平台为规范平台秩序对涉事账号停止全部或部分服务）均由账号登记主体承担。
2．如您在本平台发布商业性质信息，您应当自行审查确保信息符合《广告法》、《反不正当竞争法》及其他相应法律法规的规定，相关信息在本平台展示不作为本平台确认相关信息合法的证明，如因前述行为存在违规导致的一切后果由发布主体承担。
3．如您实施了本协议中禁止实施的行为或其他依据相关法律法规构成违法或侵犯第三方权益的行为，您应当对受侵害者承担赔偿责任或经相关行政主管部门确认承担罚款，如本平台在先承担了相应赔偿或罚款，视为您对本平台造成侵害，本平台有权要求您承担前述费用（包括但不限于诉讼费、律师费等相关法律程序费用）及您对本平台造成的其他损失（如有），在本平台提出主张时您应当立即支付，且您确认如存在前述情况，为保护公共利益、本平台的商业利益或为维护消费者权益，本平台有权自您授权的本平台关联支付机构中的账户中划扣相应款项，您同意并授权本平台进行上述划扣。
 <p style="font-size: 20px;font-weight: 600;margin: 10px 0px;">二、服务内容</p> 
1.本平台为商家提供在线
2.本平台为用户提供宠物行业服务机构点评业务，您可以通过在平台检索特定商家，查阅评价，在完成真实消费后也可以进行评论。
3.本平台为用户提供协助领养服务，即通过与有资质的动物救助机构合作，整理、公示待领养动物并在平台上发布领养要求等信息，用户可以通过平台与动物救助机构对接，实施领养。
4.本平台提供直播窗口，用户可以通过平台的直播服务，进行宣传、销售等商业行为。
5.本平台将会提供部分平台自营的产品。
6.本平台基于第三方结算机构mallbook为在本平台上的支付类业务提供技术支持。。
7.本平台与第三方保险公司合作在平台上提供各类保险业务，您可以基于需求及对保险条款的理解自行选择购买。
8.本平台致力于为宠物行业从业者、宠物救助机构、动物保护组织及全部爱宠人士建设功能全面、使用感良好、有利于各方良性发展的综合性平台，本平台将持续推出新服务，本协议自动构成新服务的协议部分。
9.为使用本平台服务您可能需要下载特定软件或使用特定程序，本平台为提供良好的服务、修复漏洞、提升安全性等考虑，有权对特定软件及程序进行更新，您应当确保使用的为最新版本，否则因版本落后产生的使用过程中的不便或遭受到的损失本平台不承担责任。
10.您知悉并认同，本平台有权依据您的行为调整对您提供免费服务的范围，本平台对于免费服务的设置、调整、取消享有完整且独立的权利，对相应的服务规范享有最终解释权。
<p style="font-size: 20px;font-weight: 600;margin: 10px 0px;">三、账号管理规范</p> 
1.您知悉并同意，在本平台管理账号的过程中如发现有注册信息不实、账号行为异常、账号行为违反本协议规定或相关账号可能存在侵犯本平台或第三方合法权益的情况，本平台有权独立判断暂停或终止对您提供服务，包括但不限于限制您使用部分或全部本平台服务、限制您提取资金、限制您访问账户、强制冻结、注销或删除账号。
2.您知悉并同意，如本平台发现您存在实施本协议禁止行为的，本平台有权视您行为的情节严重及所涉范围立即限制您使用相应服务并删除或屏蔽相关违规信息、服务评价，且本平台有权对您的行为进行取证，对相关数据进行留存，如有必要，本平台有权向相关行政主管部分进行举报。
3.您知悉并同意，在使用本平台的商业服务、直播服务时遵守相关法律法规的规定，无论该规定是否纳入本协议。为维护平台秩序，保障平台用户的公共利益，本平台有权依据先行有效的法律对上述行为进行审查，如存在违规情况，本平台有权暂时或永久限制您使用本平台部分或全部服务，直至冻结、注销或删除您的账号，同时本平台亦有义务配合相应主管部门进行调查。
 <p style="font-size: 20px;font-weight: 600;margin: 10px 0px;">四、知识产权</p> 
1.本平台对以下内容享有完整的知识产权（包括但不限于商标权、著作权、专利权）：
（1）平台服务中提供的内容、信息（包括但不限于程序、软件、网页、文字、图片、音频、视频、图表、版面设计、电子数据等）
（2）本平台用于提供或承载上述内容信息的网络平台、数据库、应用程序等
（3）本平台在提供服务过程中提供的商标、商业形象、商业标识、技术、文字等
（4）本平台在提供服务、运营、维护等过程中产生的所有信息及数据。

本平台所拥有的上述权利及所提供的服务中包括的其他内容的知识产权收到法律保护，未经得到本平台的书面授权，您承诺不实施、不放任他人通过您注册的账户实施亦不得协助他人以任何形式获取上述内容或对上述内容进行任何形式的使用、复制、传播、修改、抓取、监视、引用或用于二次创作。
2.您承诺在使用本平台的软件或小程序时不对本平台实施以下行为：
（1）删除、隐藏本平台软件程序上的商标权、主权者或其他权利标识信
（2）复制、改编、传播本平台软件程序或其上数据的任何部分
（3）对本平台使用的软件或程序进行反向工程、反向汇编、反向编译或以任何方式尝试获得本平台软件或程序的源代码
（4）使用任何方式对本平台软件程序或其中存在的数据进行任何变动
3.您承诺在本平台上传、存储、发布、传播的内容（包括但不限于文字、图片、影像、音频、及其他数字信息）均为自行创作或已经获得必要授权，您的上传、存储、发布、传播行为不会侵犯他人的知识产权或其他合法权益。
4.您知悉并同意，您一旦接受本服务协议，即表明您主动将上述内容的非专属、可转让的财产性权利，如著作权（包括但不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），在全世界范围内永久、免费、独家且不可撤销地授权给本平台及本平台的关联方，本平台及关联方可基于该等授权使用上述内容（包括但不限于用于商业用途）或向第三方自主进行任何必要的转授权。该等授权、转授权的使用场景包括但不限于当前或其他任何网站、应用程序、产品或移动终端设备等，且本平台及其关联公司有权自行或授权第三方对上述授权内容进行修改、复制、改编、翻译、汇编或制作，形成衍生产品。在不违反相关法律法规的强制性规定、尊重相关原始授权内容的知识产权的基础上，该等衍生产品的相关知识产权归本平台及关联方或本平台或本平台关联方所授权许可的第三方所有。
5.您确认并同意授权本平台及本平台关联方以自己的名义或委托专业第三方针对有关您上传、提交或发布的内容（含衍生作品）的侵权行为进行独立自主的维权并获得全部赔偿。维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等。本平台及本平台关联方有权对维权事宜做出独立决策并予以实施。
6.您知悉并同意，因您通过本平台上传、发布的任何内容存在知识产权侵权的，如本平台或本平台的关联方因处理该侵权纠纷产生损失的，您应当赔偿前述全部损失。
7.本协议已经构成《著作权法》第二十六条所规定的许可使用合同，自用户在本平台发布的相应受著作权法保护的作品内容时即生效，无论该内容形成于本协议签订前还是本协议签订后。本平台及本平台关联方有权使用或转授权第三方对前述内容进行使用，使用方式包括但不限于交流、展示、统计、合作，使用范围包括但不限于各软件客户端、网站、出版物、海报、公众号文章、微信小程序等。
<p style="font-size: 20px;font-weight: 600;margin: 10px 0px;"> 五、服务的变更、中止与终止</p> 
（一）变更
1．在本协议履行过程中，本平台有权因法律法规的要求、用户需求、经营调整等原因对服务进行各类调整。
2．服务调整前本平台会提前7日以平台公告的形式将服务调整内容进行说明，服务调整后您继续使用本平台的视为对平台调整的认可，如您不认可该等调整行为，应当于调整生效前停止使用本平台。
3．服务内容的变更一般不影响您与本平台基于本协议订立的相关规范，如变更后服务规则与本协议产生冲突的，以较新的规则为准。
（二）中止
1．除因前述情况外，本平台在进行系统更新及服务模块变动等系统维护情况下存在暂时中止服务的情况，本平台会预告并持续显示中止时间、时长及调整板块直至中止事由结束。
2．您在本平台享有的各类具有期限的权益均不受服务中止的影响，但本平台存在过错的情况或系统进行更新维护的情况除外，前述情况下权益期限落于前述事由发生期间的，权益期限停止计算直至相关在事由结束。 
（三）终止
1.您有权按照本协议约定及本平台引导注销账号，账号注销完毕服务即终止。
2.本平台有权依据本协议约定永久冻结、注销、删除账号，在此情况下，服务自账号的永久冻结、注销、删除之时终止。
3.本平台有权根据自身的商业安排经合理提前通知后，于通知期限届期时停止对您提供的全部服务，则本协议于本平台提供的服务依法定程序终止之日终止。
4.本协议终止后，本平台不再负有相您提供任何服务的义务，也不负有继续为您保存您在本平台上留存的信息的义务。

服务的变更、中止或终止均不影响本协议中知识产权条款及隐私保护条款的效力，不影响双方依据本协议要求对方履行协议履行期间产生的责任的能力，不阻碍任意一方要求另一方承担违约责任的权利。
<p style="font-size: 20px;font-weight: 600;margin: 10px 0px;">六、隐私保护政策 </p> 
（一）定义
1．个人信息：是以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人的各种信息，包括自然人的姓名、出生日期、身份证件号码、生物识别信息、住址、电话号码、电子邮箱、健康信息、行踪信息等。
2．个人敏感信息：指身份证件号码、生物识别信息、银行账号、财产信息、行动信息、14岁以下（含）儿童的个人信息等
3．平台数据：是用户使用本平台时及本平台在提供服务中在本平台上传、下载、产生的由本平台技术服务处理的数据。
（二）权益保障
1．本平台将依法充分保障用户的知情权，确保用户明确将要提供的信息名称、性质并确保用户在充分知情的前提下作出自愿、明确的授权。
2．在您首次打开本平台时，本平台会通过弹窗、文本链接及附件等简洁明显且易于访问的方式，向用户告知涵盖个人信息处理主体、处理目的、处理方式、处理类型、保存期限等内容的个人信息处理规则。
3．本平台会采取非默认勾选的方式征得您的同意，您有权于阅读完毕本规则后自主决定是否同意。
4．在取得您同意前或者用户明确表示拒绝后，本平台不会处理您的个人信息；个人信息处理规则发生变更的，本平台会重新取得您的同意。
5．如本平需要向本平台以外的第三方或关联方提供个您的人信息的，会向用您知对方的身份信息、联系方式、处理目的、处理方式和个人信息的种类等事项，并取得您的同意。
6．在处理您的种族、民族、宗教信仰、个人生物特征、医疗健康、金融账户、个人行踪等敏感个人信息的，会对您进行单独告知，取得您的同意后，方可处理敏感个人信息。
7．本平台承诺，从事您的个人信息处理活动的，具有明确、合理的目的，并遵循最小必要原则，不得从事超出用户同意范围或者与服务场景无关的个人信息处理活动。
8．在您主动注销账号时，本平台将根据相关法律法规的要求尽快清除您的个人信息。
（三）可能收集的信息
为向您提供本平台的服务或产品，本平台可能需要收集有关您个人身份的信息如下：
1.个人身份证明（包括身份证、医师资格证、专业技术资格证/职称证、医师执业证等）
2.生日、籍贯、性别、兴趣爱好、个人电话号码、住址、工作信息;
3.网络身份标识信息（包括系统账号、IP地址、电子邮箱地址及与前述有关的密码、口令、口令保护答案、用户个人数字证书等）
4.个人财产信息（优惠券、兑换码等）
5.通讯信息（短信、音视频记录及内容）
6.联系人信息（通讯录信息、短信信息）
7.个人上网记录和日志信息
8.设备信息
9.软件列表唯一设备识别码
10.个人位置信息（包括区域范围信息及精准定位信息）
11.其他为提供服务而需要收集的信息
（四）信息收集的目的
1．账户注册：您注册时，应当至少提供手机号码及密码，我们将通过发送短信验证码的方式验证您的身份是否有效，您在注册成为本平台用户后才能使用本平台的服务。
2．您可以通过补充邮箱账号增加登录及密码召回渠道。
3．您可以完善昵称、密码、头像、联系地址、出生日期等相关信息用于丰富服务体验，查找周边的商户、用于收货地址等。
4．如您需要在本平台上进行评论、交互、购买产品或服务，本平台需要您提供真实姓名和有效身份证件的号码和复印件，以便于进行实名认证。如果您不提供这些信息，可能会影响您对本平台部分核心业务功能的正常使用。
5．如过您需要在本平台登记为医生，您需要提供医院电话、《执业医师资格证》、《医师执业证书》、《职称证》、您本人的真实头像、有效身份证正反面照片等材料进行验证。
6．本平台会通过您流程的手机号码、邮箱等联系方式为您推送您可能感兴趣的活动、产品、服务等，如您不需要可以回复退订怎则本平台不再会向您推送该类商业信息。
7．为了向您提供特定服务而要求您提交特定的信息。
（五）信息的保存与管理
1．本平台会采用符合业界标准的，合理且必要的安全防护措施为您的个人信息提供保障，防止个人信息遭到未经授权的访问、公开、使用、篡改、损坏或squid。
2．本平台会限制工作人员访问您个人信息的权限，保证访问您个人数据的员工是拥有授权并且出于为您提供特定服务最低限度对你的个人信息进行接触。
3．本平台将持续为员工开展个人信息安全与隐私保证的培训课程。
4．您有权在账号后台对您的个人信息进行修改、变更授权范围、删除个人信息。
（六）无需授权的情形
1．与国家安全、国防安全有关
2．与公共安全、公共卫生、重大公共利益有关的
3．司法行政机关要求披露的
4．与刑事案件有关的
5．存在感受证明特定信息主体存在恶意或滥用权利，违法本协议约定的
6．涉及商业秘密
（七）其他
1．本平台的隐私政策可能会变更。
2．未经您明确同意，本平台不会削减您按照声明和隐私所应享有的权利。
3．如有变更，本平台会通过本协议约定的方式向您变更的内容、生效时间等。
4．如有重大变更，本平台可能通过弹出等方式进行更为显著的提示，如果您对变更存在不认可，请不要确认接受条款。
5．本声明和隐私所指的重大变更包括但不限于:
（1）本平台的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等
（2）本平台在所有权结构、组织架构等方面发生重大变化。如业务调整，公司合并分立、并购等引起的所有者变更等
（3）个人信息共享、转让或公开披露的主要对象发生变化;
（4）您参与个人信息处理方面的权利及其行使方式发生重大变化
（5）本平台负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时
（6）个人信息安全影响评估报告表明存在高风险时。
 <p style="font-size: 20px;font-weight: 600;margin: 10px 0px;">七、不可抗力及免责</p> 
1.您知悉并同意，在本平台提供服务过程中，可能存在不能预见、不可避免且不可克服的
不可抗力阻碍本平台提供服务，因不可抗力导致本平台无法提供服务从而给您造成的损失，本平台在法律允许范围内免责，但本平台将尽最大可能降低不可抗力事由对双方的影响。
2.上述不可抗力情况包括但不限于极端天气、电力故障、信息网络故障、遭到
计算机病毒等恶意程序的破坏或黑客攻击、系统故障、火灾、爆炸、暴乱、战争、骚乱、大规模公共卫生时间、政府行为、司法行政机关命令、第三方无法按约定提供服务、设备维护等其他不可抗力情况。
3.如因用户操作不当、设备问题、或使用非本平台授权的方式使用本平台服务
导致的不利后果本平台不承担责任。
4.您知悉并同意，除明确以本平台名义提供的服务，本平台不对任何通过本平
台进行的任何商业及非商业行为负责，本平台上展示的信息需要您自行判断、求证、甄别其真实性及合法性，本平台不对任何用户、商家或第三方的行为提供任何形式的担保，用户与其他主体产生纠纷的，由实际存在服务或产品提供接受关系的双方自行解决，本平台没有义务承担纠纷产生的任何损失。
 <p style="font-size: 20px;font-weight: 600;margin: 10px 0px;">八、法律适用及争议解决</p> 
1.本协议的订立、成立、生效、履行、变更、解释与纠纷处理均适用中华人民共和国合同法法律并排除一切冲突法规的适用。
2.因本平台提供的某项服务或本平台行为导致您与本平台存在争议的，您同意应当由该特定服务的服务条款或相关规则中约定的争议发生地有管辖权的法院管辖。
3.基于本协议产生纠纷的，您同意纠纷由本平台注册地人民法院管辖。
 <p style="font-size: 20px;font-weight: 600;margin: 10px 0px;"></p> 九、其他
1.您知悉并同意，本平台可以会通过以下一种或多种付出向您送达重要通知：
(1)在平台显著位置进行公示
(2)通过平台消息方式送达注册账号后台
(3)向您注册时留存的手机号码、邮箱发现电子信息。
(4)向您注册时提交的实际地址邮寄纸质载体信息
2.您应当确保本人掌管注册账号并保证损提供的联系方式均为正确有效的，如发生变动，您应当即使更新，如因您未能提供准确的通知渠道，本平台未能及时完成通知的，相应责任由您承担。
3.如您对本协议有任何疑问或建议，欢迎您在工作时段联系平台工作人员。

感谢您的耐心阅读！
      </div>
    </div>
    
    <div class="footer">
      <div>
        备案号： <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery">鲁ICP备2023043711号-1</a>
      </div>
    </div>
    
  </div>
</template>


<script>

export default {
  data() {
    return {


    };
  },
  created() {

  },
  mounted() {

  },

  methods: {

  }
};
</script>
<style>
.footer {
  width: 100%;
  height: 100px;
  background-color: rgb(149, 148, 148);
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  justify-content: center;
}

.footer img {
  width: 20px;
  height: 20px;

}

 
/* 统一板块宽度和左右居中 */
 *{
    margin: 0px;
    padding: 0px;
 }
 .header{
    width: 100%;
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0px 30px;
    justify-content: space-between;
    box-sizing: border-box;
 }
 .header img{
    width: 200px;
    height: 50px;
    object-fit: contain;
 }
 .header_search{
    display: flex;
    align-items: center;
    font-size: 18px;

 }
 .header_search input{
    width: 200px;
    height: 30px;
    border-radius: 10px;
    padding-left: 10px;
 }
.header_con {
    width: 1002px;
    height: 100px;
    /* background: pink; */
    /* 左右居中 */
    margin: 0 auto;
}
 
.header_con h1 {
    width: 604px;
    height: 66px;
    /* background: orange; */
    float: left;
    padding: 34px 0 0 20px;
}
 
.header_con form {
    width: 227px;
    height: 61px;
    /* background: orangered; */
    float: right;
    padding-top: 39px;
    padding-right: 21px;
    
}
 
.header_con .search {
    width: 195px;
    height: 26px;
    background: #f1f1f1;
    border: 1px solid #e5e5e5;
    /* 清除右侧边框 */
    border-right: none;
    float: left;
    color: #888888;
}
 .header_con img{
    width: 30px;
    height: 30px;
 }
.header_con .btn {
    width: 30px;
    height: 26px;
    border: none;
    /* background: #f1f1f1 url("../image/search_03.jpg") no-repeat center; */
}
 
.header_con form div {
    width: 30px;
    height: 26px;
    border: 1px solid #e5e5e5;
    border-left: none;
    float: left;
    /* 给btn套盒子的时候也要加浮动 */
}
 
#nav {
    height: 58px;
    background: rgb(0, 0, 0);
}
 
#nav .nav_con {
    width: 1002px;
    height: 58px;
    background: black;
    margin: 0 auto;
}
 
 
/* 导航横向排列 */
 
#nav .nav_con li {
    width: 118px;
    height: 58px;
    float: left;
    background: black;
    /* 左右居中 */
    /* text-align: center; */
    /* 上下居中 */
    line-height: 58px;
    border-left: 1px solid #4a4a4a;
    font-size: 12px;
}
 
.nav_con li a {
    color: white;
}
 
.margin-left {
    margin-left: 25px;
    border-left: none!important;
}
 
 
/* 公共样式的footer */
 
.footer_con {
    height: 82px;
    /* background: #cccccc; */
}
 
.footer_con .footer_l {
    height: 58px;
    float: left;
    /* background: red; */
    padding-top: 24px;
}
 
.footer_con .footer_l a {
    font-size: 12px;
    float: left;
    color: #868686;
    border-right: 1px solid #868686;
    /* padding:字体和边框的距离哈 */
    padding: 0 7px;
    padding-left: 15px;
}
 
.footer_con .footer_l .footer-right {
    border-right: none!important;
}
 
.footer_con .footer_r {
    height: 57px;
    color: #8a8a8a;
    font-size: 12px;
    padding-top: 25px;
    /* background: pink; */
    padding-right: 21px;
    float: right;
}
.banner_con,
.news_con,
.case_con,
.links_con,
.footer_con {
    width: 1002px;
    margin: 0 auto;
}
 .header_img{
    width: 100%;
    height: 800px;
    background: url("https://yichongdianping.oss-cn-beijing.aliyuncs.com/uploads/20240220/108f950588342170d8bbc46a7951a094.webp") no-repeat;
    background-size: 100% 100%;
 }
#banner {
    height: 580px;
    /* background:  url("https://yichongdianping.oss-cn-beijing.aliyuncs.com/uploads/20240220/e8f3128c9a69bcb1418614c9ceec74fa.webp") no-repeat center; */
}
 
#banner .banner_con {
    height: 800px;
    /* background: pink; */
    margin: 0 auto;
}
 
.news_con {
    height: 243px;
    background: white;
}
 
.news_title {
    /* background: cadetblue; */
    font-size: 18px;
    color: #3f434f;
    line-height: 18px;
    padding-top: 36px;
}
 
.news_list {
    /* background: cornflowerblue; */
    margin-top: 23px;
    padding-right: 20px;
    color: #66676c;
}
 
.news_list li {
    width: 437px;
    height: 25px;
    /* background: url("../image/ico_05.jpg") no-repeat left; */
    padding-left: 14px;
    /* margin-top: 23px;不能给li加哦 要给他爹，不然每个儿子顶部都会空出来*/
}
 
.news_list li a {
    float: left;
    font-size: 12px;
    color: #565656;
    line-height: 25px;
}
 
.news_list li span {
    float: right;
    color: #9a9a9a;
    font-size: 12px;
}
 
.news_l {
    width: 300px;
    height: 243px;
    /* background: rebeccapurple; */
    float: left;
    padding-left: 21px;
}
 
.news_c {
    width: 300px;
    height: 243px;
    /* background: #f1f1f1; */
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;

    float: left;
    padding: 0 27px 0 20px;
}
 .news_c p{
    /* 展示5行,超出后省略号 */
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    /* 溢出部分隐藏 */
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 25px;
 }
.news_c .txt1 {
    font-size: 12px;
    color: #555555;
    line-height: 25px;
    margin-top: 34px;
    /* 行高自己去量哦 */
}
 
.news_c .txt2 {
    font-size: 12px;
    color: #979797;
    line-height: 25px;
    margin-top: 15 px;
    /* 行高自己去量哦 */
}
 
.news_r {
    width: 250px;
    height: 243px;
    /* background: #fbfbfb url("../image/ma_05.jpg") no-repeat right bottom; */
    float: left;
    padding: 0 38px 0 24px;
}
 
.news_r .txt3 {
    font-size: 12px;
    color: #585858;
    line-height: 24px;
    margin: 21px 0 22px 0;
}
 
.case_con {
    min-height: 304px;
    padding: 20px 0px;
    /* background: goldenrod; */
}
 
.case_con h3 {
    font-size: 18px;
    color: #3f434f;
    /* background: pink; */
    padding: 28px 0 18px 22px;
}
 
.case_con .case_box {
    min-height: 240px;
    /* background: orange; */
}
 
.case_box dl {
    width: 210px;
    /* 这是由图片尺寸为210得出的，这样文本就会屈居于一处 */
    /* background: burlywood; */
    float: left;
    margin: 0 20px;
}
 
 
/* 这里很重要！一定要设置宽高，也就是图片尺寸得出，这样以后图片更换，结构就不会乱！ */
 
.case_box dl dt {
    width: 210px;
    height: 136px;
}
 
.case_box dl dd {
    font-size: 12px;
    color: #4f4f4f;
    line-height: 24px;
    margin-top: 13px;
}
 
.case_box{
    display: flex;
    justify-content: center;
}
.case_box div{
    flex: 1;
    /* border: 1px solid #e5e5e5; */
    padding:0px 20px;
    color: #5d5c5c;
}
/* 上面给dt这个容器指定了宽高，现在让img跟随这个容器的宽高！！ */
 
.case_box dl dt img {
    width: 100%;
    height: 100%;
}
 
#links {
    background: #e5e5e5;
}
 
.links_con {
    height: 250px;
    /*   */
}
 
.links_con .links_title {
    color: #5d5d5d;
    line-height: 16px;
    border-bottom: 1px solid #c1c1c1;
    padding: 31px 0 11px 13px;
}
 
.links_con .links_l {
    width: 452px;
    height: 250px;
    background: #e5e5e5;
    float: left;
    margin-left: 20px;
}
 
.links_con .links_list {
    /* background: cornsilk; */
    height: 170px;
    padding-left: 5px;
    padding-top: 15px;
}
 
.links_con .links_list li {
    width: 136px;
    height: 24px;
    /* background: url("../image/btn_img_13.jpg") no-repeat left center; */
    font-size: 12px;
    line-height: 24px;
    padding-left: 13px;
    float: left;
}
 
.links_con .links_list li a {
    color: #5f5f5f;
}
 
.links_con .links_c {
    width: 153px;
    height: 250px;
    background: #e5e5e5;
    float: left;
    margin: 0 50px;
}
 
.links_con .links_c .links_list li {
    width: 130px;
}
 
.links_con .links_r {
    width: 256px;
    height: 250px;
    background: #e5e5e5;
    float: left;
}
 
.links_con .links_r .map {
    /* text-align: center; */
    padding-top: 11px;
}

</style>
